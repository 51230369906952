import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Configurar Claves SAT
            </h2>
            <p>
                A esta altura, es importante que ya tengas configuradas las claves predeterminadas en "Mi configuración". <br />
                <span className="small quote">Si te saltaste ese paso, entra <Link to="../claves-default">aquí</Link> antes de continuar</span>                
            </p>
            <ImageFooted explanation={`En la vista de edición múltiple, selecciona primero la categoría y luego la subcategoría de interés. Podrás editar desde 1 solo producto hasta la subcategoría completa.`}>
                <IMG_Mercadolibre file={`productoEditMultiple`} />
            </ImageFooted>
            <p>

            </p>
            <ImageFooted explanation={`Busca las claves textualmente si no conoces el número, cuando la encuentes, es necesario dar click a la opción, de lo contrario no se configurarán adecuadamente.`}>
                <IMG_Mercadolibre file={`productoEditMultipleSat`} />
            </ImageFooted>
            <SectionNavigate next={`/ecommerce/mercadolibre/cancelar-facturas`} previous={`/ecommerce/mercadolibre/productos`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;